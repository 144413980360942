/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCFWa3HTu4xSKQuQ756U49dN-rSApn5Rbo",
  "appId": "1:733268918820:web:8557480f2946cf24864eb1",
  "authDomain": "schooldog-i-gilmer-ga.firebaseapp.com",
  "measurementId": "G-51Q3NFS9MY",
  "messagingSenderId": "733268918820",
  "project": "schooldog-i-gilmer-ga",
  "projectId": "schooldog-i-gilmer-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-gilmer-ga.appspot.com"
}
